export const useBaseUrl = () => {
    const {
        public: {
            api: { baseUrl },
        },
    } = useRuntimeConfig();

    // Return base url with a guaranteed trailing slash
    return baseUrl.replace(/\/$/, "") + "/";
};
